import './App.css';

import Header from './Components/Header/Header'; 
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Map from './Components/Map/Map';



function App() {
  return (
    <div className="App">
      <Header />
      {/* <Contact /> */}
      <Map />
      <Footer />
    </div>
  );
}

export default App;
