import React from 'react';

// Icon Imports
import { Instagram, Mail, Coffee } from 'lucide-react'


import style from './Contact.module.css';

function Contact() {

    return (
        <div className={style.contactGrid}>
            <div> <a href="mailto:hello@donaldscardiff.co.uk"><Mail color="#f0e7d6" size={48} /></a></div>
            <div> <a href="https://www.instagram.com/donaldscardiff/"><Instagram color="#f0e7d6" size={48} /></a></div>
            <div><Coffee color="#f0e7d6" size={48}/></div>
        </div>
    )
}

export default Contact;