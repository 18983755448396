import React, { useRef } from 'react';
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import style from './Header.module.css';
import logo from '../../assets/donaldslogo.webp';
import donald from '../../assets/donalds1.webp'
import Contact from '../Contact/Contact';


gsap.registerPlugin(DrawSVGPlugin)




function Header() {

    const container = useRef();
    var tl = gsap.timeline();

    useGSAP(() => {
        gsap.fromTo(".img",   {opacity: 0}, { opacity: 1, duration: 1, stagger: 0.1})

    }, [])

    return (
        <div className={`container ${style.header}`} ref={container}>
            <img className="img" src={donald} alt="brown image with cream outline of donald which reads donald's coffi and peis"></img>
            <Contact />
        </div >
    )
}

export default Header; 