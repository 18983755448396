import React from 'react'; 

import style from './Map.module.css'

function Map() {
    return (
        <div className={style.mapContainer}>
            <iframe className={style.map} src="https://api.mapbox.com/styles/v1/ronanpd/clrth3rfj00ir01plc900dyft.html?title=false&access_token=pk.eyJ1Ijoicm9uYW5wZCIsImEiOiJjbHJmMWNpamwwMGQxMmpwYm83Y2tnY2txIn0.JJD2DiBYpgRhcSCsvQUKNQ&zoomwheel=false#16.17/51.515902/-3.245214" title="Donalds"></iframe>
        </div>
    )
}

export default Map; 